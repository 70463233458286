import { useEffect, useState , MouseEvent} from "react";
import { TrashService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { IPagination } from "../../../interfaces";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Button } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import { ITrashBlogRow, ITrashBlogState, IBlog } from "../../../interfaces";
import { capitalize, createIndex } from "../../../utilities/helper";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CustomTypography from "../../../components/mui/max-length-limit";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import TrashBlogFilters from "./filters";

const TrashBlog = () => {
  let rows: ITrashBlogRow[] = [];
  const navigate = useNavigate();
  const { getBlogTrashs, recoverBlogTrash } = TrashService();
  const [search, setSearch] = useState<string>("");
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<ITrashBlogState>({
    recoverWarning: false,
    _trash: "",
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      category: []
    },
    filterCount: 0,
    searching: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
  });

  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getTrash = useQuery({
    queryKey: ["allTrashs", state.pagination.page, state.searching, state.filters],
    queryFn: () => getBlogTrashs({
      isDeleted: true, pagination: true, page: state.pagination.page, limit: state.pagination.limit, sort: false,...state.filters
    }
    )
  });

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
    const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
    const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];

    filterCount += category.length ? 1 : 0;
    filterCount += product.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;
    filterCount += quarter.length ? 1 : 0;


    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key),
        productId: product.map(item => item.key),
        quarterId: quarter.map(item => item.key),
        subCategoryId: subCategory.map(item => item.key),
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {
    if (getTrash.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getTrash.data.meta.page,
          totalPages: getTrash.data.meta.totalPages,
          totalRecords: getTrash.data.meta.totalRecords
        }
      }));
    }
  }, [getTrash.data?.meta]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleRecover = (_trash = "") => {
    setState(prevState => ({
      ...prevState,
      recoverWarning: !prevState.recoverWarning,
      _trash
    }
    ));
  };

  const onRecover = async () => {
    try {
      const recovered = await recoverBlogTrash({ _id: state._trash });
      snackbar(recovered.message, "info");
      navigate({ pathname: "/trash/blog-trash", search: searchParams.toString() });
      handleRecover();
      getTrash.refetch();

    } catch (error) {
      console.log(error);
    }
  };


  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "trash_name",
      label: "Blog Title"
    },
    {
      id: "trash_quarter",
      label: "Quarter"
    },
    {
      id: "trash_category",
      label: "Category"
    },
    {
      id: "trash_subCategory",
      label: "Sub Category"
    },
    {
      id: "trash_product",
      label: "Product"
    },
    {
      id: "action",
      label: "Action"
    },
  ];


  const createRow = (index: number, blog: IBlog, pagination: IPagination) => {
    const action = (
      <Grid item md={3}>
        <Button fullWidth type="submit" onClick={() => handleRecover(blog._id)} startIcon={<RestoreFromTrashIcon />}>
          RECOVER
        </Button>
      </Grid>
    );

    return {
      id: createIndex(pagination, index),
      trash_name: (
        <CustomTypography
          limit={30}
          label={capitalize(blog.title)}
        />
      ),
      trash_quarter: capitalize(blog?._quarter?.name || ""),
      trash_category: capitalize(blog?._category?.name || ""),
      trash_subCategory: capitalize(blog?._subCategory[0]?.name || ""),
      trash_product: capitalize(blog?._product?.name || ""),
      action,
    };
  };
  if (getTrash.data?.data.length) {
    rows = getTrash.data?.data.map((blog, i) => createRow(i, blog, state.pagination));
  }

  return (
    <>
      <Header
        className='my-2'
        btnText="Add New Blog"
        searchPlaceholder="Search by title"
        onSearch={onSearch} 
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <TrashBlogFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.recoverWarning}
        onClose={() => handleRecover()}
        onConfirm={() => onRecover()}
        title="Recover Blog"
        description="Are you sure you want to recover this blog?"
      />
      <Outlet context={{ reFetch: getTrash.refetch }} />
    </>
  );
};
export default TrashBlog;