import { useQuery } from "@tanstack/react-query";
import { MouseEvent } from "react";
import { Box,FormControl, Select, MenuItem, SelectChangeEvent, Tooltip, Rating } from "@mui/material";
import { ChangeEvent, useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { IErrorResponse } from "../../../interfaces";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IVendorManage, IVendorRow , IVendorState} from "../../../interfaces/vendor";
import { VendorsService } from "../../../services/vendor";
import CustomTypography from "../../../components/mui/max-length-limit";
import { capitalize } from "../../../utilities/helper";
import VendorFilters from "./filter";

interface outletProps {
  refetchVendors: () => void;
}

const Vendor = () => {
  let rows: IVendorRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getVendors, deleteVendor, updateVendorStatus } = VendorsService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IVendorState>({
    deleteWarning: false,
    _vendor: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      categories: [],
      primaryServices: [],
      secondaryServices: [],
      status: [],
      rating: []
    },
    filterCount: 0,
  });

  const vendors = useQuery({
    queryKey: ["allVendors", state.pagination.page, state.filters],
    queryFn: () =>
      getVendors({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        ...state.filters,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (vendors.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: vendors.data.meta.page,
          totalPages: vendors.data.meta.totalPages,
          totalRecords: vendors.data.meta.totalRecords,
        },
      }));
    }
  }, [vendors.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const primaryServices: { key: string, value: string }[] = searchParams.get("primaryServices") ? JSON.parse(String(searchParams.get("primaryServices"))) : [];
    const secondaryServices: { key: string, value: string }[] = searchParams.get("secondaryServices") ? JSON.parse(String(searchParams.get("secondaryServices"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const rating: { key: string, value: string }[] = searchParams.get("rating") ? JSON.parse(String(searchParams.get("rating"))) : [];
    filterCount += category.length ? 1 : 0;
    filterCount += primaryServices.length ? 1 : 0;
    filterCount += secondaryServices.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += rating.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        categories: category.map(item => item.key),
        primaryServices: primaryServices.map(item => item.key),
        secondaryServices: secondaryServices.map(item => item.key),
        status: status.map(item => item.key),
        rating: rating.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_vendor = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _vendor,
    }));
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onDelete = async () => {
    try {
      const vendor = await deleteVendor({ _id: state._vendor });
      snackbar(vendor.message, "info");
      handleDelete();
      vendors.refetch();
      outlet?.refetchVendors && outlet.refetchVendors();
    } catch (error) {
      console.log("Error in vendor delete", error);
    }
  };

  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value
      };
      const response = await updateVendorStatus(payload);
      snackbar(response.message, "info");
      vendors.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log({ "Error in update vendor status": error });
    }
  };

  const columns = [
    {
      id: "id",
      label: "Vendor ID",
    },
    {
      id: "vendor_name",
      label: "Vendor Name",
    },
    {
      id: "vendor_category",
      label: "Category",
    },
    {
      id: "vendor_primary_services",
      label: "Primary Services Offered",
    },
    {
      id: "vendor_secondary_services",
      label: "Secondary Services Offered",
    },
    {
      id: "contact_person",
      label: "Contact Persons",
    },
    {
      id: "vendor_rating",
      label: "Rating",
    },
    {
      id: "vendor_status",
      label: "Status",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];


  const createRow = (index: number, vendor: IVendorManage) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Notes",
            method: () => {
              searchParams.set("type", "notes");
              navigate({ pathname: "manage/" + vendor._id, search: searchParams.toString()});
            },
            disabled: !resourceAllocate("vendor.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(vendor._id),
            disabled: !resourceAllocate("vendor.remove"),
          },
        ]}
      />;

    const status =
    <FormControl
      sx={{ width: 150 }}
    >
      <Select
        size="small"
        name="status"
        onChange={e => handleStatus(e, vendor?._id)}
        value={vendor?.status}
      >
        <MenuItem disabled value="none">Select</MenuItem>
        <MenuItem value="ACTIVE">Active</MenuItem>
        <MenuItem value="INACTIVE">Inactive</MenuItem>
      </Select>
    </FormControl>;

    const name = <CustomTypography
          limit={30}
          label={capitalize(vendor.vendorName)}
          onClick={() => navigate({ pathname: "manage/" + vendor._id, search: searchParams.toString() })}
          color={"primary"}
      />;

    const primaryServices =
      <Tooltip title={vendor?._vendorPrimaryServices?.map(ser => ser.name).join(", ")}>
        <span>
          {vendor && vendor?._vendorPrimaryServices && vendor?._vendorPrimaryServices.length > 0 ? (
            <>
              {capitalize(vendor._vendorPrimaryServices[0].name)}{" "}
              {vendor._vendorPrimaryServices.length > 1 && (
                <span style={{ color: "blue" }}>+{vendor._vendorPrimaryServices.length - 1}</span>
              )}
            </>
          ) : (
            "-"
          )}
        </span>
      </Tooltip>;

    const secondaryServices =
      <Tooltip title={vendor?._vendorSecondaryServices?.map(ser => ser.name).join(", ")}>
        <span>
          {vendor && vendor?._vendorSecondaryServices && vendor?._vendorSecondaryServices.length > 0 ? (
            <>
              {capitalize(vendor._vendorSecondaryServices[0].name)}{" "}
              {vendor._vendorSecondaryServices.length > 1 && (
                <span style={{ color: "blue" }}>+{vendor._vendorSecondaryServices.length - 1}</span>
              )}
            </>
          ) : (
            "-"
          )}
        </span>
      </Tooltip>;

    const contactPersons =
    <Tooltip title={vendor?.contactInformation?.map(per => per.name).join(", ")}>
      <span>
        {vendor && vendor?.contactInformation && vendor?.contactInformation.length > 0 ? (
          <>
            {capitalize(vendor.contactInformation[0].name)}{" "}
            {vendor.contactInformation.length > 1 && (
              <span style={{ color: "blue" }}>+{vendor.contactInformation.length - 1}</span>
            )}
          </>
        ) : (
          "-"
        )}
      </span>
    </Tooltip>;

  const rating = (
    <Box display="flex" alignItems="center">
      <Rating
        name="vendor-rating"
        value={vendor?.rating}
        readOnly
        precision={0.5}
        size="small"
      />
      <Box ml={1}>{`(${vendor?.rating})`}</Box>
    </Box>
  );

    return {
      id: vendor.vendorId,
      vendor_name: name,
      vendor_category: capitalize(vendor._vendorCategory?.name),
      vendor_primary_services: primaryServices,
      vendor_secondary_services: secondaryServices,
      contact_person: contactPersons,
      vendor_rating: vendor?.rating ? rating : "No Rating Added",
      vendor_status: status,
      action,
    };
  };

  if (vendors.data?.data.length) {
    rows = vendors?.data?.data.map((vendor, i) =>
      createRow(i, vendor)
    );
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by name or email"
        onSearch={onSearch}
        btnText="Add Vendor"
        onBtnClick={
          resourceAllocate("vendor.write") ? () => navigate("new") : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
          <VendorFilters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add vendor to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Vendor"
        description="Are you sure you want to delete this vendor?"
      />
      
      <Outlet context={{ ...outlet, reFetch: vendors.refetch }} />
    </div>
  );
};

export default Vendor;
