import Joi from "joi";
import { manySkillMatrixValidation } from "../../../../validations";
import { ISkillMatrix } from "../../../../interfaces";
import { useParams } from "react-router-dom";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";

interface IData {
    // eslint-disable-next-line
    success: any[];
    failed: {
        [x: string]: string | number;
    }[];
}

const useSkillMatrixValidateImport = () => {
    const { id } = useParams();
    const { getPartialSkillItemCategory } = SkillItemCategoryService();
    const validateSkillMatrixUpload = async (leads: Array<Array<string | number>>) =>

    // create a promise so that when finished, we get desired results
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            let row = 2;

            //create a  data Object in which we have two arrays - success and failed

            const data: IData = {
                success: [],
                failed: [],
            };
            
            // create a array of objects to store data
            const items: { name: string }[] = [];
 


            // now we iterate over the leads i.e each row
            for await (const lead of leads) {

                // if row is empty, skip
                if (!lead?.length) {
                    ++row;
                    continue;
                }
               
                // create a object to store errrors
                const errors: {
                    [x: string]: string;
                } = {};
                
                // create a object to store results

                const skillCategory = await getPartialSkillItemCategory({ name: lead[1], _skillParameter: id });
                const skillItem = {
                    _skillCategory: skillCategory && skillCategory.data && skillCategory.data._id || "",
                    name: String(lead[2]) ?? "",
                };

                // we push success results in the data object
                items.push(skillItem);
                
                // apply validations on each rows
                const validated: Joi.ValidationResult<ISkillMatrix> = manySkillMatrixValidation.validate(skillItem, { abortEarly: false });
               
               // check if in this particular row any errror
                validated.error?.details.forEach(element => {
                    errors[String(element.context?.label)] = element.message;
                });

                // if there is any error, we push it in the failed array
                const keys = Object.keys(errors);
                if (keys.length) {
                    data.failed.push({ ...errors, row });
                }
                ++row;
            }

            const result = {
                skill: id,
                items: items,
            };

            data.success.push(result);

            resolve({
                data: data.success,
                errorMessage: data.failed,
            });

        });

    return { validateSkillMatrixUpload };
};

export default useSkillMatrixValidateImport;

