import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RoleService } from "../../../../services";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IDataSet, IErrorResponse, IRole, IRoleField } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { roleValidation } from "../../../../validations";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import CustomTable from "../../../../components/mui/table";
import { capitalize, mergeMultipleJson } from "../../../../utilities/helper";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Header from "../../../../components/header";
import CustomLabel from "../../../../components/mui/custom-label";
import analyticsRole from "../../../../assets/json/resources/analytics/resource.json";
import candidateAndCareerRole from "../../../../assets/json/resources/candidate-career-management/resource.json";
import cmsRole from "../../../../assets/json/resources/cms/resource.json";
import configurationRole from "../../../../assets/json/resources/configuration/resource.json";
import dashboardRole from "../../../../assets/json/resources/dashboard/resource.json";
import directoryRole from "../../../../assets/json/resources/directory/resource.json";
import resourcePlanningRole from "../../../../assets/json/resources/resource-planning/resource.json";
import skillMatrixRole from "../../../../assets/json/resources/skill-matrix/resource.json";
import TrashRole from "../../../../assets/json/resources/trash/resource.json";


interface outletProps {
    reFetch: () => void
}

interface IResource {
    name: string;
    read?: string;
    module: string;
    write?: string;
    remove?: string;
    edit?: string;
    title?: string;
}

interface IState {
    resources: string[];
}

const ManageRole = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const [search, setSearch] = useState("");
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const { getRole, addRole, updateRole } = RoleService();
    const combineResource = mergeMultipleJson(
      analyticsRole,
      candidateAndCareerRole,
      cmsRole,
      configurationRole,
      dashboardRole,
      directoryRole,
      resourcePlanningRole,
      skillMatrixRole,
      TrashRole
    );
    const roleResources = combineResource.map( resource => resource.data).flat();
    const searchedResourceRaw = search.length ? combineResource
    .map(combineResource => ({
        name: combineResource.name,
        data: combineResource.data.filter(resource => resource.name.toLowerCase().includes(search.toLowerCase()))
    }))
    .filter(dataset => dataset.data.length > 0): combineResource;


    const searchedResource = searchedResourceRaw.map(dataset => ({
        name: dataset.name,
        data: dataset.data.length > 0 ? dataset.data : dataset.data
    }));

    const team = useQuery({ queryKey: [hitQuery], queryFn: () => getRole({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm<IRole>({
        resolver: joiResolver(roleValidation),
        defaultValues: {
            name: "",
            description: ""
        }
    });
    const [state, setState] = useState<IState>({
        resources: []
    });
    const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (id !== "new") {
            if (team.data?.data) {
                setValue("name", capitalize(team.data.data?.name));
                setValue("description", capitalize(team.data.data?.description));

                setState(prevState => ({
                    ...prevState,
                    resources: team.data.data?.resources
                }));

                setValue("resources", team.data.data?.resources);
            }
        }
    }, [id, team.data]);  

    const handleResource = (checked: boolean, resources: Array<string | undefined>) => {
        const filterResources: string[] = [];
        let stateResources: string[] = [];
        if (checked) {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources, ...filterResources];

            if (resources.includes("lead.write")) {
                if (!stateResources.includes("job.read")) {
                    stateResources.push("job.read");
                }
                if (!stateResources.includes("college-recruitment.read")) {
                    stateResources.push("college-recruitment.read");
                }
                if (!stateResources.includes("college.read")) {
                    stateResources.push("college.read");
                }
                if (!stateResources.includes("user.read")) {
                    stateResources.push("user.read");
                }
            }
            if(resources.includes("cms-blog-analytics.read")){
                if (!stateResources.includes("cms-product-title.read")) {
                    stateResources.push("cms-product-title.read");
                }
            }

            stateResources = [...new Set(stateResources)];
            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        } else {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources];
            stateResources = stateResources.filter(resource => !filterResources.includes(resource));

            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        }

        setValue("resources", stateResources);
    };

    const onSubmit = async (data: IRole) => {
        let resources: string[] = [...state.resources];

        resources.forEach(resource => {
            const roleResource = roleResources.find(roleResource =>
                (roleResource.read === resource) ||
                (roleResource.write === resource) ||
                (roleResource.edit === resource) ||
                (roleResource.remove === resource)
            );

            if (roleResource) {
                resources.push(roleResource.module);
            }

        });

        const roleModules = roleResources.filter(roleResource =>
            (!resources.includes(roleResource.read || "")) &&
            (!resources.includes(roleResource.write || "")) &&
            (!resources.includes(roleResource.remove || "")) &&
            (!resources.includes(roleResource.edit || "")) &&
            (resources.includes(roleResource.module))
        );



        if (roleModules) {
            roleModules.forEach(roleResource => {
                resources.splice(resources.indexOf(roleResource.module), 1);
            });
        }


        resources = [...new Set(resources)];
        const payload = { ...data, resources };

        try {
            if (id === "new") {
                const add = await addRole(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/roles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
            else {
                const update = await updateRole({ ...payload, _id: id });
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/roles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
            reset();
            setState(prev => ({ ...prev, resources: [] }));
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/roles",
            search: searchParam.toString()
        });
    };
    
    const fields: IRoleField[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type role name here",
            required: true
        },
        {
            label: "Description",
            name: "description",
            type: "input",
            placeholder: "Type role description here",
            required: true
        },
    ];

    const columns = [
        {
            id: "title",
            label: "Title"
        },
        {
            id: "all",
            label: "All"
        },
        {
            id: "read",
            label: "Read"
        },
        {
            id: "write",
            label: "Write"
        },
        {
            id: "edit",
            label: "Edit"
        },
        {
            id: "remove",
            label: "Delete"
        },
    ];

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>, field: IRoleField) => {
      const input = event.target as HTMLInputElement;
      const inputValue = input.value;
      const isNameField = field.name === "name";
      const isDescriptionField = field.name === "description";
      let maxLength;

      if (event.key === "Enter" || (event.key === " " && inputValue.length === 0)) {
        event.preventDefault();
      }
    
      if (isNameField) {
        maxLength = 30;
      } else if (isDescriptionField) {
        maxLength = 100;
      } else {
        maxLength = 30;
      }
    
      if (inputValue.length >= maxLength && event.key !== "Backspace" && event.key !== "Delete") {
        event.preventDefault();
      }
    };

    const createRow = (resource: IResource) => ({
        title: resource.name,
        all: <Checkbox
            onChange={(e, c) => handleResource(c, [resource.read || undefined, resource?.write || undefined, resource?.edit || undefined, resource?.remove || undefined])}
            checked={state.resources?.includes(resource.read || "") && state.resources?.includes(resource.write || "") &&
             state.resources?.includes(resource.remove || "") && state.resources?.includes(resource.edit || "")} />,
        read: <Checkbox
            checked={state.resources?.includes(resource.read || "") ? true : false}
            disabled={resource.read ? false : true}
            onChange={(e, c) => handleResource(c, [resource.read])} />,
        write: <Checkbox
            checked={state.resources?.includes(resource.write || "") ? true : false}
            disabled={resource.write ? false : true}
            onChange={(e, c) => handleResource(c, [resource?.write || undefined])} />,
        edit: <Checkbox
            checked={state.resources?.includes(resource.edit || "")}
            disabled={!resource.edit}
            onChange={(e, c) => handleResource(c, [resource?.edit || undefined])} />,
        remove: <Checkbox
            checked={state.resources?.includes(resource.remove || "") ? true : false}
            disabled={resource.remove ? false : true}
            onChange={(e, c) => handleResource(c, [resource?.remove || undefined])} />,
    });


    return (
      <Box >
        <CustomDialog
          size="md"
          title={id !== "new" ? "Edit Role" : "Add Role"}
          isOpen={!!id}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
         >
          <Grid container spacing={4} className="form-fields">
            {fields.map((field) => {
              if (field.type === "input") {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={<CustomLabel label={field.label} required={field.required}/>}
                          placeholder={field.placeholder}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                          onKeyDown={(event) => handleKeyDown(event, field)}
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Select
                      control={control}
                      name={field.name}
                      label={field.label}
                      size="small"
                      variant="outlined"
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                        >
                      {field.children}
                    </Select>
                  </Grid>
                );
              }
            })}
          </Grid>

          <Box mt={2} mb={1}>     
          <Header
            searchPlaceholder="Search by role title"
            onSearch={onSearch}
            preventEnterPress={true}
          />
          </Box> 
            <div className="accordian-table-container" >
            {errors?.resources && (
                        <FormHelperText
                          error={!!errors?.resources}
                        >
                          {errors?.resources?.message}
                        </FormHelperText>
                      )}

          {searchedResource.map((item: IDataSet, index: number) => (
            <Accordion
              className="accordion"
              key={index}
              expanded={expandedPanel === item.name}
              onChange={handleAccordionChange(item.name)}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box overflow="auto">
                    <Box>
                      <CustomTable
                        columns={columns}
                        rows={item.data.map((item: IResource) =>
                          createRow(item)
                        )}
                        height="calc(100vh - 400px)"
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}

          </div>
        </CustomDialog>
      </Box>
    );
};

export default ManageRole;