import "./style.scss";
import { Box, Button,Drawer, IconButton, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SkillMatrixService } from "../../../../services/skill-matrix";
import { capitalize } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import { IUpdateSkillMatrixRow } from "../../../../interfaces/skill-matrix/edit-skill-matrix/manage/index";
import { useNavigate, useParams, useSearchParams,useOutletContext } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import useResource from "../../../../hooks/useResource";
import CloseIcon from "@mui/icons-material/Close";
import useUser from "../../../../hooks/useUser";


interface IoutletProps {
    reFetch:() => void;
    reFetchUserSkills: () => void;
    reFetchSkillItemData: () => void;
  }
const UpdateSkillMatrix = () => {
    const {isSuperAdmin, resourceAllocate} = useResource();
    const navigate = useNavigate();
    const [rows, setRows] = useState<IUpdateSkillMatrixRow[]>([]);
    const [editedRows, setEditedRows] = useState<IUpdateSkillMatrixRow[]>([]);
    const { user } = useUser();
    const { itemId } = useParams();  
    const {snackbar} = useSnackbar();
    const outlet = useOutletContext<IoutletProps>();
    const [searchParam] = useSearchParams();
    const { UpdateSkillMatrix ,addSkillMatrixRequest,updateSkillMatrixRequest, deleteSkillMatrix} = SkillMatrixService();
    const hitQuery = (itemId === "new" || itemId === "view") ? false : true;
    const skillMatrixs = useQuery({ queryKey: [hitQuery], queryFn: () => UpdateSkillMatrix({ _id: itemId }), enabled: hitQuery });
    
    const handleRequest = async () => {
        const requestData = editedRows.filter((row) => row?._itemId !== undefined).map((row) => ({
            _itemId: row?._itemId?._id,
            nextScore: row?.updatedLevel,
            previousScore:row?.currentLevel
        }));

        const payload = {
            _skillMapping:skillMatrixs?.data?.data?._id,
            items:[...requestData],
        };
        if(payload.items.length){
            const response = await addSkillMatrixRequest(payload);
            snackbar(response.message, "info");
            navigate({
                pathname: `/skill-matrix/manage/${skillMatrixs?.data?.data?._skillParam?._id}`,
                search: searchParam.toString()
            });
        }else{
            snackbar("No values are to be updated.", "warning");
        }
   
    };
    const handleUpdate = async () => {
        const requestData = editedRows.filter((row) => row?._itemId !== undefined).map((row) => ({
            _itemId: row?._itemId?._id,
            nextScore: row?.updatedLevel,
            previousScore:row?.currentLevel
        }));
        const payload = {
            _id:skillMatrixs?.data?.data?._id,
            skillItems:[...requestData],
        };
        const response = await updateSkillMatrixRequest(payload);
        snackbar(response.message, "info");
        setEditedRows([]);
        navigate({
            pathname: `/skill-matrix/manage/${skillMatrixs?.data?.data?._skillParam?._id}`,
            search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        outlet.reFetchUserSkills && outlet.reFetchUserSkills();
        outlet.reFetchSkillItemData && outlet.reFetchSkillItemData();
    };


    const handleEditUpdatedLevel = (id:string,name:string,rowIndex: number,previousScore:number, value: number) => {
       if(value >= 0 && value <= 5){
        setEditedRows(prevEditedRows => {
            const updatedRowsCopy = [...prevEditedRows];
            updatedRowsCopy[rowIndex] = {
                ...updatedRowsCopy[rowIndex],
                updatedLevel:value,
                currentLevel:previousScore,
                _itemId:{
                    _id:id,
                    skillItems:name
                }
            };
            return updatedRowsCopy;
        });
       }else{
        snackbar("Please enter value between 0 to 5","warning");
       }
    };

    
    const onClose = () => {
        navigate({
            pathname: `/skill-matrix/manage/${skillMatrixs?.data?.data?._skillParam?._id}`,
            search: searchParam.toString()
        });
    };
    const onDeleteEmployee = async (_id :string) => {
        const response = await deleteSkillMatrix({_id});
        outlet?.reFetch && outlet.reFetch();
        outlet.reFetchUserSkills && outlet.reFetchUserSkills();
        snackbar(response.message,"info");
        onClose();
      };

    const columns = [
        {
            id: "skillItems",
            label: "Skills"
        },  
        {
            id: "skillCategory",
            label: "Skill Category"
        },
        {
            id: "currentLevel",
            label: "Current Level"
        },
        {
            id: "updatedLevel",
            label: "Updated Level",
            editable:true
            
        },
    ];
    const createRows = () => {
        const items = skillMatrixs.data?.data?.skillItems || [];
        return items.map(item => ({
            _itemId: {
                _id: item?._itemId?._id,
                skillItems: capitalize(item?._itemId?.name),
            },
            currentLevel: Number(item.nextScore) ,
            updatedLevel: Number(item.nextScore),
            skillCategory: capitalize(item && item._itemId && item._itemId._skillCategory && item._itemId._skillCategory.name || ""),
            skillItems: capitalize(item?._itemId?.name),
        }));
    };

    useEffect(() => {
        if (skillMatrixs.isSuccess) {
            const newRows = createRows();
            setRows(newRows);
        }
    }, [skillMatrixs?.data]);

    const skillMatrixName = skillMatrixs && skillMatrixs.data && skillMatrixs.data.data && skillMatrixs.data.data. _skillParam && skillMatrixs.data.data._skillParam.name || "";
    

    
    return (
        <Drawer anchor="right"
            open={itemId ? true : false}
            onClose={onClose}
        >
            <Box sx={{ width: 500, height: "100vh", padding: "16px" }}>
                <Box className="employee-box">
                    <Box className="employee-inner-box">
                        <strong className="update-heading">UPDATE REQUEST</strong>
                    </Box>
                    <IconButton onClick={onClose} size="small" className="cross-icon">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box>
                <p className="update-heading-skill">
                    <b>Skill Matrix: </b> 
                    <Tooltip title={skillMatrixName}>
                        <span>
                            {skillMatrixName.length > 20 ? `${skillMatrixName.slice(0, 20)}...` : capitalize(skillMatrixName)}
                        </span>
                    </Tooltip>
                </p>
                <p className="update-heading-employee">
                    <b>Employee: </b>
                    {capitalize(skillMatrixs && skillMatrixs.data && skillMatrixs.data.data && skillMatrixs.data.data. _user && skillMatrixs.data.data._user.name || "") }
                    </p>  
                </Box>
                <Box>
                    <CustomTable
                        columns={columns}
                        rows={rows.map((row, index) => ({
                            ...row,
                            updatedLevel: (
                                <input
                                    type="number"
                                    key = {row?.updatedLevel}
                                    defaultValue={row?.updatedLevel } 
                                    value={editedRows[index]?.updatedLevel || ""}
                                    onChange={(e) => handleEditUpdatedLevel(row?._itemId?._id, row?._itemId?.skillItems, index,row?.currentLevel, Number(e.target.value))}
                                    className="Input"
                                    onKeyDown={(e) => {
                                        if (
                                          e.key === "e" || e.key === "E" || 
                                          e.key === "+" || e.key === "-" || 
                                          e.key === "Enter"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                                        const paste = e.clipboardData.getData("text");
                                        if (/[eE+-]/.test(paste)) {
                                          e.preventDefault(); 
                                        }
                                      }}
                                    
                                />
                            ),
                        }  ))}
                        height="calc(100vh - 215px)"
                    />
                   
                        <Box className="action-box action-buttons">
                            <Box>
                                <Button variant="outlined" color="error" disabled={!resourceAllocate("skill-user-mapping.remove")} onClick={() => onDeleteEmployee(itemId || "")}>Delete employee</Button>
                            </Box>
                            <Box>
                                <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
                                {
                                    isSuperAdmin()  ||
                                    skillMatrixs && skillMatrixs.data && skillMatrixs.data.data && skillMatrixs.data.data._user && skillMatrixs.data.data._user._id !== user._id ?
                                    <Button onClick={handleUpdate}>Update</Button> :
                                    <Button onClick={handleRequest}>Request</Button>
                                }
                            </Box>
                        </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default UpdateSkillMatrix;