import { Box, Grid, IconButton, Switch, TextField, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState, MouseEvent } from "react";
import { Outlet, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IErrorResponse, IPagination } from "../../../../interfaces";
import Header from "../../../../components/header";
import useDebounce from "../../../../hooks/useDebounce";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomTable from "../../../../components/mui/table";
import GetActions from "../../../../components/get-actions";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { capitalize, createIndex } from "../../../../utilities/helper";
import DragIndicatorIcon from "@mui/icons-material/DragHandle";
import AutomationService from "../../../../services/configuration/automation";
import { IAutomationState, IAutomationRow, IAutomation } from "../../../../interfaces/configuration/automation";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import useResource from "../../../../hooks/useResource";
import { DropResult } from "react-beautiful-dnd";
import CustomDialog from "../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import AutomationFilters from "../filters";
interface outletProps {
  refetchAutomations: () => void;
}

const Automation = () => {
  let rows: IAutomationRow[] = [];
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAutomations, deleteAutomation , updateActiveStatus, updateAutomationPosition, updateAutomationPositionByButton} = AutomationService();
  const moduleName = searchParams.get("module") || "candidate";
  const [automationPos, setAutomationPos] = useState(false);
  const [automationState, setAutomationState] = useState({
    module: "",
    oldPosition: 0,
    _id: ""
  });


  const [state, setState] = useState<IAutomationState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      module: (moduleName === "candidate" ? "LEAD" : moduleName.toUpperCase()),
      search: "",
      source: [],
      engagementType: [],
      status: [],
      jobType: [],
      communicationType: [],
      category: []
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
    _automationId: "",
    deleteWarning: false,
    rows: [],
  });

  // calling Api to get list Data
  const automationData = useQuery({
    queryKey: ["allAutomation", state.pagination.page, state.filters],
    queryFn: () => getAutomations({ pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters })
  });

  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (automationData.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        rows: automationData.data?.data.map((automation, i) =>
          createRows(i, automation, state.pagination)
        ),
      }));
    }
  }, [automationData.data?.meta]);

  useEffect(() => {
    if (automationData.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        rows: automationData.data?.data.map((automation, i) =>
          createRows(i, automation, state.pagination)
        ),
      }));
    }
  }, [automationData.data?.data]);


  useEffect(() => {
    if (automationData?.data?.data?.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: automationData?.data?.meta?.page,
          totalPages: automationData?.data?.meta?.totalPages,
          totalRecords: automationData?.data?.meta?.totalRecords
        }
      }));
    }

    if (!searchParams.get("module")) {
      searchParams.set("module", "candidate");
      setSearchParams(searchParams);
    }

  }, [automationData?.data?.meta, searchParams.get("module")]);

  useEffect(() => {

    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const source: { key: string, value: string }[] = searchParams.get("source") ? JSON.parse(String(searchParams.get("source"))) : [];
    const engagementType: { key: string, value: string }[] = searchParams.get("engagementType") ? JSON.parse(String(searchParams.get("engagementType"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const communicationType: { key: string, value: string }[] = searchParams.get("communicationType") ? JSON.parse(String(searchParams.get("communicationType"))) : [];
    const jobType: { key: string, value: string }[] = searchParams.get("jobType") ? JSON.parse(String(searchParams.get("jobType"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    filterCount += source.length ? 1 : 0;
    filterCount += engagementType.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += communicationType.length ? 1 : 0;
    filterCount += jobType.length ? 1 : 0;
    filterCount += category.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        source: source.map(source => source.key),
        engagementType: engagementType.map(type => type.key),
        status: status.map(status => status.key),
        communicationType : communicationType.map(user => user.key),
        jobType : jobType.map(user => user.key),
        category : category.map(user => user.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  let columns = [
    {
      id: "drag",
      label: <DragIndicatorIcon />,
      type: "common"
    },
    {
      id: "id",
      label: "S No",
      type: "common"
    },
    {
      id: "title",
      label: "Title",
      type: "common"
    },
    {
      id: "communicationType",
      label: "Communication Type",
      type: "common"
    },
    {
      id: "template",
      label: "Template",
      type: "common"
    },
    {
      id: "jobType",
      label: "Job Type",
      type: "candidate"
    },
    {
      id: "engagementType",
      label: "Engagement Type",
      type: "candidate"
    },
    {
      id: "source",
      label: "Source",
      type: "candidate"
    },
    {
      id: "category",
      label: "Category",
      type: "cpd"
    },
    {
      id: "status",
      label: "Status",
      type: "common"   
    },
    {
      id: "action",
      label: "Actions",
      type: "common"
    }
  ];

  columns = columns.filter(column => (column.type === "common" || column.type === searchParams.get("module"))); 

  const handleDelete = (_automationId = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _automationId
    }));
  };

  const handlePositonDialog = (_id: string, position: number, module: string) => {
    setValue("position", (position+1).toString());
    setAutomationState({
      oldPosition: position,
      _id: _id,
      module: module
    });
    setAutomationPos(true);
  };

  const onDelete = async () => {
    try {
      const response = await deleteAutomation({ _id: state._automationId, module: state.filters.module});
      snackbar(response.message, "info");
      handleDelete();
      automationData.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      console.log("Error in Deleting in Automation", err);
    }
  };

  const { control, handleSubmit,  formState: { errors }, setValue } = useForm<{position: string}>({
    resolver: joiResolver(Joi.object({ position: Joi.number().required().label("Position") })),
    defaultValues: {
      position: ""
    }
  });

  const onDrag = async (e: DropResult) => {
    const payload = [...state.rows];
    const row = payload[e.source.index];
    if (e.destination?.droppableId) {
      payload.splice(e.source.index, 1);
      payload.splice(e.destination?.index, 0, row);
      setState((prevState) => ({
        ...prevState,
        rows: payload,
      }));

      const position: { _id: string; position: number }[] = [];
      payload.forEach((automation, index) =>
        position.push({ _id: automation._id, position: (state.pagination.page-1)*state.pagination.limit + index })
      );
      try {
        const updated = await updateAutomationPosition({ automations: position });
        snackbar(updated.message, "info");
        automationData.refetch();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onPositionChange = async (data: {position: string}) => {
    if(Number(data.position) < 1 || (automationData?.data?.meta && Number(data.position) > automationData?.data?.meta.totalRecords)){
      snackbar("Enter a valid position.", "error");
    }else{
      const payload = {
        oldPosition: automationState.oldPosition,
        newPosition: Number(data.position) - 1,
        _id: automationState._id,
        module: automationState.module
      };
      try {
        const updated = await updateAutomationPositionByButton( payload );
        snackbar(updated.message, "info");
        automationData.refetch();
      } catch (error) {
        console.log(error);
      }
      setAutomationPos(false);
    }
  };

  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    try {
      const user = await updateActiveStatus({ _id: id, "isActive":checked });  
      snackbar(user.message, "info");
      automationData.refetch();
      outlet?.refetchAutomations && outlet.refetchAutomations(); 
    } catch (error) {
      snackbar("Error in user status update", "error");
    }
  };

  const createRows = (index: number, automation: IAutomation, pagination: IPagination) => {

    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: automation?._id, search: searchParams.toString() }) },
        { name: "Change Position", method: () => handlePositonDialog(automation?._id, automation?.position, automation?.module) },
        { name: "Delete", method: () => handleDelete(automation?._id) }
      ]}
    />;

    const status = 
      <Switch
        disabled={!resourceAllocate("automation.write")}
        onChange={(event, checked) => onStatusUpdate(event, checked, automation._id)}
        checked = {automation.isActive}
      />;

    const jobType =
      <Tooltip title={automation?.filters?.jobType?.map(src => src).join(", ")}>
        <span>
        {automation?.filters?.jobType && (automation?.filters?.jobType?.length > 1 ? (
        <>
          {capitalize(automation?.filters?.jobType[0])}
          {" "}
          <span style={{ color: "blue" }}>+{automation?.filters?.jobType?.length - 1}</span>
        </>
       ) : (
        capitalize(automation?.filters?.jobType[0])
        ))}
        </span>
      </Tooltip>;

    const engagementType =
      automation?.filters?.engagementType && (automation?.filters?.engagementType?.length > 1 ? (
        <>
          {capitalize(automation?.filters?.engagementType[0])}
          {" "}
          <span style={{ color: "blue" }}>+{automation?.filters?.engagementType?.length - 1}</span>
        </>
      ) : (
        capitalize(automation?.filters?.engagementType[0])
      ));

    const source =
      <Tooltip title={automation?.filters?.source?.map(src => src).join(", ")}>
        <span>
        {automation?.filters?.source && (automation?.filters?.source?.length > 1 ? (
            <>
              {capitalize(automation?.filters?.source[0])}
              {" "}
              <span style={{ color: "blue" }}>+{automation?.filters?.source?.length - 1}</span>
            </>
          ) : (
            capitalize(automation?.filters?.source[0])
          ))}
        </span>
      </Tooltip>;
      

    const category =
      <Tooltip title={automation?.filters?.category?.map(src => src).join(", ")}>
        <span>
        {automation?.filters?.category && (automation?.filters?.category?.length > 1 ? (
        <>
          {capitalize(automation?.filters?.category[0])}
          {" "}
          <span style={{ color: "blue" }}>+{automation?.filters?.category?.length - 1}</span>
        </>
      ) : (
        capitalize(automation?.filters?.category[0])
      ))}
        </span>
      </Tooltip>;

    return {
      drag: (
        <Tooltip title="Drag row">
          <DragIndicatorIcon />
        </Tooltip>
      ),
      id: createIndex(pagination, index),
      _id: automation._id,
      title: automation.title,
      communicationType: capitalize(automation?.communicationType),
      template: typeof automation._template !== "string" ? capitalize(automation._template?.title || automation?.whatsappTemplateName) : "",
      jobType,
      engagementType,
      source,
      category,
      status,
      action
    };
  };

  if (automationData?.data?.data?.length) {
    rows = automationData?.data?.data?.map((autoData, index: number) => createRows(index, autoData, state.pagination));
  }

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Tooltip title="Back">
          <IconButton className="back mr-3" onClick={() => navigate("/configurations/automation")}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Header
          className="my-2 w-100"
          searchPlaceholder="Search by title"
          onSearch={onSearch}
          btnText={`Add ${moduleName} Automation`}
          onBtnClick={resourceAllocate("automation.write") ? () => navigate({ pathname: "new", search: searchParams.toString() }): undefined}
          onFilter={openFilter}
          filterCount={state.filterCount}
        >
          <AutomationFilters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
        </Header>
      </Box>

      <Box>
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 254px)"
          errorMessage="Add User to see the Data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
          onDrag={onDrag}
          isDND={true}
          draggableId="drag"
        />
      </Box>
      <CustomDialog
            title="Manage position of automation"
            isOpen={automationPos}
            onClose={() => {setAutomationPos(false);
              setValue("position", "");
            }}
            onSubmit={handleSubmit(onPositionChange)}
            size="sm"
      >
        <Grid item md={10}>
          <Controller
            control={control}
            name="position"
            render={(prop) => <TextField
            label="Enter New Position*"
            variant="outlined"
            size="small"
            placeholder="Enter new position"
            type="number"
            error={errors.position ? true : false}
            helperText={errors["position"]?.message}
            {...prop.field}
            />}
          />
        </Grid>

        </CustomDialog>
        
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Automation"
        description="Are you sure you want to delete this Automtion?"
      />
      <Outlet context={{ reFetch: automationData.refetch }} />
    </div>
  );
};

export default Automation;