import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { keywordFilterInitialState } from "../../common/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";
import { IKeywordFilterState } from "../../../../interfaces/content/keyword";
import ProjectsService from "../../../../services/content/projects-products";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const KeywordFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getContentCategorys } = CategoryService();
    const { getContentSubCategorys } = SubCategoryService();
    const { getContentProjects } = ProjectsService();

    const getCmsCategory = useQuery({ queryKey: ["allCmsCategories"], queryFn: () => getContentCategorys({}) });
    const getCmsSubCategory = useQuery({ queryKey: ["allCmsSubcategories"], queryFn: () => getContentSubCategorys({}) });
    const getProducts = useQuery({ queryKey: ["allProducts"], queryFn: () => getContentProjects({}) });

    const categoryList = getCmsCategory?.data?.data || [];
    const subCategoryList = getCmsSubCategory?.data?.data || [];
    const products = getProducts?.data?.data || [];


    const [state, setState] = useState<IKeywordFilterState>({ ...keywordFilterInitialState });

    useEffect(() => {
        resetFilter();
    }, []);

    useEffect(() => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
        const product : { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];

        setState(prevState => ({
            ...prevState,
            category,
            subCategory,
            product
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name:  "category" | "subCategory" | "product", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name:"category" | "subCategory" | "product" , key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            category: [],
            subCategory: [],
            product: [],
        });
    };

    const onApply = () => {
        searchParams.set("category", JSON.stringify(state.category));
        searchParams.set("subCategory", JSON.stringify(state.subCategory));
        searchParams.set("productId", JSON.stringify(state.product));

        searchParams.set("page", "1");
        navigate({
            pathname: "/content/keywords",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onCancel = () => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];

        setState(prevState => ({
            ...prevState,
            category,
            subCategory
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onCancel}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.subCategory.length || state.category.length || state.product.length) ?
                                <>
                                    {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.subCategory.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("subCategory", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.product.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("product", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onCancel}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            <MenuItem
                                index={0}
                                label="Category"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.category}
                            />
                            <MenuItem
                                index={1}
                                label="Subcategory"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.subCategory}
                            />
                            <MenuItem
                                index={2}
                                label="Product"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.product}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                categoryList.map(category => <ListItemButton
                                    key={category?._id}
                                    selected={state.category.find(ele => ele.key === category._id) ? true : false}
                                    onClick={() => handleRightListItem("category", category._id, category.name)}
                                >
                                    <ListItemText primary={category.name} />
                                    <Checkbox edge="end" checked={state.category.find(ele => ele.key === category._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                subCategoryList.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={state.subCategory.find(ele => ele.key === data._id) ? true : false}
                                    onClick={() => handleRightListItem("subCategory", data._id, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={state.subCategory.find(ele => ele.key === data._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 2 &&
                                products.map((data) => <ListItemButton
                                    key={data._id}
                                    selected={!!state.product.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("product", data._id, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={!!state.product.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default KeywordFilters;